<template>
  <div class="tree-content">
    <div
        class="tree-label context-menu"
        :class="{ 'emphasis-color': $isMarkEmphasis(item)}"
    >
      <div v-if="iconMaps[item.iconType]" class="tree-icon d-center">
        <svg-icon :icon-class="iconMaps[$getIconType(item)].icon"
                  class-name="size-3 icon-color-white"
                  :class="[{active: item.active && !$isMarkEmphasis(item)}, iconMaps[$getIconType(item)].className||'']"/>
      </div>
      <div v-else class="tree-icon d-center">
        <svg-icon v-if="!item.parent" icon-class="new-02" class-name="size-3 icon-color-white"/>
        <svg-icon v-else icon-class="new-04" class-name="size-3 icon-color-white"/>
      </div>
      <input
          ref="input"
          v-model="value"
          placeholder="请输入名称"
          @blur="onBlur"
          @keydown="onKeydown"
          @focus="onFocus"
      />
    </div>
    <span class="tree-right" v-if="edit && !selectEnabled">
      <el-icon class="tree-edit-icon">
        <Finished/>
      </el-icon>
    </span>
    <span
        class="tree-right"
        v-else-if="!selectEnabled"
        :class="isMobile ? 'show-block' : ''"
    >
      <el-button class="button-001"
                 link
                 size="small"
                 @click="onMenu($event, 'new')"
                 v-if="!isMobile && menuType===1"
      >
        <svg-icon icon-class="new-31" class="d-center size-1"></svg-icon>
      </el-button>
      <el-button
          class="button-002"
          link
          size="small"
          @click="onMenu($event, 'map')"
          v-if="!isMobile && menuType===1"
      >
        <svg-icon icon-class="new-11" class="d-center size-1"></svg-icon>
      </el-button>
      <el-button link size="small" @click="onMenu($event, 'more')">
        <svg-icon icon-class="new-more" class="d-center size-1"></svg-icon>
      </el-button>
    </span>
  </div>
</template>
<script>
import {iconMaps} from "../../../../utils/TreeConstants";

export default {
  props: {
    menuType: {type: Number, default: 1},
    isMobile: {type: Boolean, default: false},
    title: {type: String, default: undefined},
    item: {type: Object, default: undefined},
    selectEnabled: {type: Boolean, default: false},
  },
  computed: {
    value: {
      get() {
        return this.title;
      },
      set(val) {
        this.$emit("update:title", val);
      },
    },
  },
  data() {
    return {
      edit: false,
      //图标配置放在这里
      iconMaps: iconMaps
    };
  },
  methods: {
    focus() {
      this.edit = true;
      let treeItem = this.$refs.input.parent("tree-item");
      if (treeItem) treeItem.classList.add("tree-edit");

      this.$nextTick(() => {
        this.$refs.input.focus();
      });
    },
    onMenu(e, type) {
      e.stopPropagation();
      this.$emit("onMenu", this, type, {
        clientX: e.clientX,
        clientY: e.clientY,
      });
    },
    onKeydown(e) {
      if (e.key === "Enter") {
        this.$refs.input.blur();
      }
    },
    onFocus() {
      this.$root.cursorElement = true;
      this.edit = true;
      this.tempTitle = this.title;
      if (this.tempTitle === "新建模型") {
        this.$refs.input.select();
      }
      let treeItem = this.$refs.input.parent("tree-item");
      if (treeItem) treeItem.classList.add("tree-edit");
      this.$emit("input-focus");
    },
    onClickInput(e) {
      e.stopPropagation();
    },
    onBlur() {
      this.$root.cursorElement = false;
      let treeItem = this.$refs.input.parent("tree-item");
      if (treeItem) treeItem.classList.remove("tree-edit");
      this.edit = false;
      if (this.tempTitle !== this.title) {
        this.$emit("onRename", this.title);
        if (!this.title) {
          this.value = this.tempTitle;
        }
      }
    },
    onDoubleClick() {
    },
    onClick(e) {
      if (this.selectEnabled) {
        this.$emit("onClick");
        return;
      }
      if (this.edit) {
        e.stopPropagation();
        return;
      }
      if (!this.timer) {
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$emit("onClick");
          this.timer = null;
        }, 200);
      } else {
        clearTimeout(this.timer);
        this.timer = null;
        this.edit = true;
        let treeItem = this.$refs.input.parent("tree-item");
        if (treeItem) treeItem.classList.add("tree-edit");
        this.$nextTick(() => {
          this.$refs.input.focus();
        });
      }
    },
  },
};
</script>
<style lang="scss">
.emphasis-color {
  input, .tree-icon svg {
    color: #de4e4e !important;
  }
}
</style>
