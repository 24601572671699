<template>
  <div class="tree-scroll" ref="listRef">
    <div class="tree-list">
      <div v-if="loading" class="tree-loading d-center">
        <!--        <div class="ui-loading middle"></div>-->
        <div class="loading-text">加载中...</div>
      </div>
      <div v-else-if="list.length === 0">
        <div class="loading-empty">试试新建一颗树</div>
      </div>
      <tree-node
        v-for="(item, index) in list"
        :sort="index + ''"
        :item="item"
        :key="item.id"
        :selectEnabled="selectEnabled"
        :activeItem="activeItem"
        :filter-item="filterItem"
        :expandMap="expandMap"
        @onSelect="onClick"
        @dblclick="onDoubleClick(item)"
        v-model:ready="item.ready"
        @onDragDown="onMouseDown"
        @onMouseMove="onMouseMove"
        @onDragEnter="onMouseEnter"
        @onExpand="onArrowExpand"
        @onContextMenu="onContextMenu"
      >
        <template #default="{ data, node }">
          <slot :data="data" :node="node"></slot>
        </template>
      </tree-node>
    </div>
    <div
      ref="drag"
      class="mouse-drag tree-list tree-readonly"
      v-if="dragNode && isDragMove"
    >
      <div v-html="dragHtml"></div>
    </div>
  </div>
</template>
<style lang="scss" scoped></style>
<script>
import TreeNode from "./TreeNode";
import $ from "jquery";
import { Platform } from "../../../../utils/utils";

export default {
  components: {
    TreeNode,
  },
  props: {
    treeType: { type: Number, default: 0 },
    initDelay: { type: Number, default: 0 },
    initList: { type: Array, default: undefined },
    loadingAni: { type: Boolean, default: false },
    load: { type: Function, default: undefined },
    initLoad: { type: Function, default: undefined },
    moveExpand: { type: Boolean, default: true },
    selectEnabled: { type: Boolean, default: false },
    activeItem: { type: Object, default: undefined },
    filterItem: { type: Object, default: undefined },
    updateNodeApi: { type: Function, default: undefined },
    cloneApi: { type: Function, default: undefined },
    deleteApi: { type: Function, default: undefined },
    isCloud: { type: Boolean, default: false },
    noActive: { type: Boolean, default: false },
  },
  data() {
    return {
      // list: [],
      dragNode: null,
      dragHtml: null,
      isDragMove: false,
      dragEndNode: null,
      loading: true,
      expandMap: {'root': true},
    };
  },
  computed: {
    list: {
      get() {
        if (this.initList) {
          return this.initList;
        }
        return this.treeType === 0
          ? this.$root.cloudTreeList
          : this.$root.localTreeList;
      },
      set(val) {
        if (this.initList) {
          return;
        }
        if (this.treeType === 0) {
          this.$root.cloudTreeList = val;
        } else {
          this.$root.localTreeList = val;
        }
      },
    },
  },
  watch: {
    initList(val) {
      this.list = val;
    },
    filterItem(val, old) {
      if (val !== old) {
        this.expandMap = {};
      }
    },
  },
  mounted() {
    this._mousemove = this.mousemove.bind(this);
    this._mouseup = this.mouseup.bind(this);
    this.initData();
  },
  methods: {
    onScroll(e) {
      // console.log('--list---');
      // const list = this.$el.querySelectorAll(".tree-item");
      // 可以做列表优化
    },
    onContextMenu(e, item) {
      this.$emit("onContextMenu", e, item);
    },
    async onArrowExpand(e, item) {
      e.stopPropagation();
      await this.expand(item);
    },
    formatStruct(list) {
      let activeItem;
      list.forEach((row) => {
        if (row.id === this.$route.params.articleId) {
          activeItem = row;
        }
        if (row.children) {
          row.children.forEach((child) => {
            child.parent = row;
          });
          let act = this.formatStruct(row.children);
          if (act) {
            activeItem = act;
          }
        }
      });
      return activeItem;
    },
    async initData() {
      if (this.list) {
        if (this.$root.activeItem && !this.noActive) {
          this.$nextTick(() => {
            // console.log('--activeItem---', activeItem)
            this.onSingleClick(this.$root.activeItem, false);
          });
        }
        this.loading = false;
        return;
      }
      if (this.selectEnabled) {
        setTimeout(() => {
          this.list = this.initList;
          this.loading = false;
        }, this.initDelay);
        return;
      }
      if (!this.initLoad) return;
      this.loading = true;
      let list = await this.initLoad();
      let activeItem = this.formatStruct(list);
      this.list = list;
      this.loading = false;
      if (!activeItem && this.isCloud) {
        if (
          !this.$route.params.articleId ||
          !this.$route.params.articleId.startsWith("local-")
        ) {
          activeItem = this.list[0];
        }
      }

      if (activeItem) {
        activeItem.active = true;
        this.$nextTick(() => {
          // console.log('--activeItem---', activeItem)
          this.onSingleClick(activeItem, false);
        });
      }
    },
    //获取节点下已经展开的节点
    async fetchAllExpand(item, level) {
      if (!item.children) {
        let list = await this.getChildren(item);
        item.children = list;
        let all = [];
        list.forEach((row) => {
          if (row.hasChildren && row.expand) {
            all.push(this.fetchAllExpand(row, level + 2));
          }
        });
        await Promise.all(all);
      }
    },
    async expandAll(item, expand) {
      this.clearDrag();

      if (!item.hasChildren) {
        return;
      }
      if (expand) {
        //一键展开
        await this.fetchAll(item);
        this.expandChildren(item, true);
      } else {
        //一键收起
        this.expandChildren(item, false);
      }
    },
    expandChildren(item, expand) {
      if (!item.hasChildren) {
        return;
      }

      if (item.expand !== expand) {
        item.expand = expand;
      }
      if (item.children) {
        item.children.forEach((row) => {
          this.expandChildren(row, expand);
        });
      }
    },
    //获取节点下所有数据
    async fetchAll(item) {
      if (!item.hasChildren) {
        return;
      }
      let data;
      if (item.children) {
        data = item.children;
      } else {
        item.loading = true;
        // if (item.loading) return;
        data = await this.getChildren(item);
        item.children = data;
      }

      let all = [];
      data.forEach((row) => {
        if (row.hasChildren) {
          all.push(this.fetchAll(row));
        }
      });
      await Promise.all(all);
    },
    async getChildren(node, check = true) {
      if (check && node && node.children) {
        return node.children;
      } else if (check && !node && !this.loading) {
        return this.getList();
      }
      // 获取一层节点
      if (this.load) {
        if (node) {
          node.loading = true;
        } else {
          this.loading = true;
        }
        let children = await this.load(node);
        if (children) {
          children.forEach((row) => {
            row.parent = node;
          });
        }
        if (node) {
          node.loading = false;
        } else {
          this.loading = false;
        }
        return children;
      }
      return [];
    },
    onSingleClick(item, checkExpand = true, isDelete=false) {
      if (this.dragEndNode || this.dragNode || this.isDragMove) return;
      if (item) {
        item.active = true;
      }
      this.$emit("onSelect", item, checkExpand, isDelete);
    },
    onDoubleClick() {
      clearTimeout(this.clickTimer);
    },
    allowClick() {
      return !(this.dragEndNode || this.dragNode || this.isDragMove || this.isMove);
    },
    async onClick(e, item) {
      if (this.selectEnabled) {
        this.$emit("update:activeItem", item);
        return;
      }
      if (!Platform.isMobile && (this.dragEndNode || this.dragNode || this.isDragMove || !item)) return;
      if (Platform.isMobile && (this.dragNode || this.isDragMove)) return;
      if (Platform.isMobile) {
        clearTimeout(this.timer2);
        this.timer2 = undefined;
        this.removeListener();
      }
      if (this.clickTimer && !this.isDragMove) {
        clearTimeout(this.clickTimer);
        this.clickTimer = undefined;
        this.$emit("onDouble", e);
        return;
      }
      clearTimeout(this.clickTimer);
      if (!item.active) {
        this.onSingleClick(item);
      }
      this.clickTimer = setTimeout(() => {
        this.allowDoubleClick = false;
        this.clickTimer = undefined;
        // this.onSingleClick(item);
      }, 200);
    },
    async expand(item) {
      if (!item) return;
      clearTimeout(this.timer2);

      if (!item.hasChildren) {
        return;
      }
      if (!item.children && !item.loading) {
        if (this.selectEnabled) {
          this.expandMap[item.id] = true;
        } else {
          item.expand = true;
        }
        item.children = await this.getChildren(item);
      } else {
        if (this.selectEnabled) {
          this.expandMap[item.id] = !this.expandMap[item.id];
        } else {
          item.expand = !item.expand;
        }
      }
    },

    getItemIndex(row) {
      if (row.parent && row.parent.children) {
        return row.parent.children.findIndex((r) => r.id === row.id);
      } else if (!row.parent) {
        return this.list.findIndex((r) => r.id === row.id);
      }
      return -1;
    },
    getList() {
      if (this.$root.curGroup) {
        return this.treeType === 0
          ? this.$root.cloudTreeList
          : this.$root.localTreeList;
      }
      return this.list;
    },
    getNextItem(row) {
      let idx = this.getItemIndex(row);
      if (idx === -1) return;
      if (row.parent && row.parent.children) {
        return row.parent.children[idx + 1];
      } else if (!row.parent) {
        return this.list[idx + 1];
      }
    },
    getPreItem(row) {
      let idx = this.getItemIndex(row);
      if (idx === -1) return;
      if (row.parent && row.parent.children) {
        return row.parent.children[idx - 1];
      } else if (!row.parent) {
        return this.list[idx - 1];
      }
    },
    onMouseMove(e, node) {
      if (!this.dragNode) return;
      if (node.item.ready) {
        let moveType = node.getMoveType(e);
        if (node.item.ready !== moveType) {
          if (moveType === "ready-box") {
            if (
              this.dragNode.item.parent &&
              node.item.id === this.dragNode.item.parent.id
            ) {
              node.item.ready = true;
              return;
            }
          } else if (moveType === "ready-bottom") {
            if (node.item.hasChildren && node.item.expand) {
              node.item.ready = true;
              return;
            }
            let nextItem = this.getNextItem(this.dragEndNode);
            // console.log('nextItem', nextItem)
            if (nextItem && nextItem.id === this.dragNode.item.id) {
              node.item.ready = true;
              return;
            }
          } else if (moveType === "ready-top") {
            let nextItem = this.getPreItem(this.dragEndNode);
            // console.log('nextItem pre', nextItem)
            if (nextItem && nextItem.id === this.dragNode.item.id) {
              node.item.ready = true;
              return;
            }
          }
          node.item.ready = moveType;
        }
      }
    },
    getChildrenLength(item) {
      return item
        ? item.children
          ? item.children.length
          : 0
        : this.getList().length;
    },
    //新增节点
    async appendItem(item, iconType, open = true, title = "新建模型") {
      if (!this.updateNodeApi) return;
      let sort = this.getChildrenLength(item);
      let res = await this.updateNodeApi({
        title: title,
        sort: sort,
        parentId: item ? item.id : undefined,
        iconType: iconType,
      });
      const newItem = {
        id: res.data,
        title: title,
        parentId: item ? item.id : undefined,
        iconType: iconType,
        hasChildren: false,
        sort: sort,
      };

      if (!item) {
        this.list.push(newItem);
        if (open) {
          this.onSingleClick(newItem, false);
        }
      } else {
        if (item.hasChildren && !item.children) {
          item.children = await this.getChildren(item);
          if (open) {
            item.expand = true;
            if (item.children.length > 0) {
              this.onSingleClick(
                item.children[item.children.length - 1],
                false
              );
            }
          }
          return;
        }
        item.hasChildren = true;
        if (!item.children) {
          item.children = [];
        }
        newItem.parent = item;
        item.children.push(newItem);
        this.$root.addTreeMap(newItem);
        if (open) {
          item.expand = true;
          this.onSingleClick(newItem, false);
        }
      }
      this.$root.addTreeMap(newItem);
      this.$emit("onAdd", newItem);
      if (this.$root.curGroup) {
        this.$emit("group-add", {
          sort: this.list.length,
          id: newItem.id,
        });
      }
      return newItem;
    },
    isExist(item, docId) {
      let exist = item.id === docId;
      if (exist) return true;
      if (item.children && item.children.length > 0) {
        for (let i = 0; i < item.children.length; ++i) {
          exist = this.isExist(item.children[i], docId);
          if (exist) return true;
        }
      }
      return false;
    },
    removeFromParent(item) {
      return this.$root.removeFromParent(item);
    },
    async deleteItem(item) {
      this.removeFromParent(item);
      if (this.deleteApi) {
        await this.deleteApi(item.id);
      }
      if (this.isExist(item, this.$route.params.articleId)) {
        if (!item.parent) {
          if (this.list.length > 0) {
            this.onSingleClick(this.list[0], false, true);
          } else {
            this.onSingleClick(undefined, false, true);
          }
        } else {
          this.onSingleClick(item.parent, false, true);
        }
      }
    },
    //移动节点到某个节点下面
    async moveToInner(item, parent, expand = true) {
      if (this.$root.curGroup && !item.parent) {
        await this.$root.deleteGroupItem(this.$root.curGroup.id, item.id, !!parent);
      }
      let sort = 0;
      if (parent.id !== 'root' && parent.hasChildren) {
        let children = await this.getChildren(parent);
        parent.children = children;
        sort = children.length;
      } else if (parent.id === 'root') {
        sort = parent.children?.length || 0;
      }
      this.removeFromParent(item);
      const newIconType = this.$getNewIconType(item, parent);
      if (this.updateNodeApi) {
        const res = await this.updateNodeApi({
          articleId: item.id,
          title: item.title,
          parentId: parent.id === 'root'? '':parent.id,
          iconType: newIconType,
          sort: sort,
        });
        if (!res.isSuccess) {
          this.$root.tips("error", res.message);
          return;
        }
      }
      item.iconType = newIconType;
      if (!parent || parent.id === 'root') {
        parent.children?.push(item);
        item.parent = undefined;
      } else {
        if (!parent.hasChildren) {
          parent.children = [];
        }
        if (parent.children) {
          item.parent = parent;
          parent.children.push(item);
          parent.hasChildren = true;
        }
        if (expand && !parent.expand) {
          parent.expand = true;
        }
      }
      this.onSingleClick(item, false);
    },
    //移动位置
    async moveItem(item, node, offset = 0) {
      let old;
      if (!this.$root.curGroup || item.parent || node.parent) {
        old = this.removeFromParent(item);
      }
      if (this.$root.curGroup && !item.parent) {
        await this.$root.deleteGroupItem(this.$root.curGroup.id, item.id, !!node.parent);
      }
      if (this.$root.curGroup && !node.parent) {
        const sort = this.list.findIndex((r) => r.id === node.id) + offset;
        this.$emit("group-add", {
          sort: sort,
          id: item.id,
        });
        if (!item.parent && !node.parent) {
          return;
        }
      }
      const oldParent = item.parent;
      item.parent = node.parent;
      let sort = 0;

      let children = await this.getChildren(node.parent);

      sort = children.findIndex((r) => r.id === node.id);
      if (sort !== -1) {
        sort += offset;
        children.splice(sort, 0, item);
      }

      let updateSorts = [];
      for (let i = 0; i < children.length; i++) {
        if (i !== sort) {
          updateSorts.push({
            articleId: children[i].id,
            sort: i,
          });
        }
      }
      const newIconType = this.$getNewIconType(item, item.parent);
      item.iconType = newIconType;
      const res = await this.updateNodeApi({
        articleId: item.id,
        title: item.title,
        parentId: item.parent ? item.parent.id : undefined,
        iconType: newIconType,
        sort: sort,
        updateSorts,
      });
      console.log('----d-d--d-d-2222', res);
      if (!res.isSuccess) {
        this.$root.tips("error", res.message);
        //还原回去
        await this.resetMove(item, oldParent, node.parent, old.sort)
        return;
      }
      this.onSingleClick(item, false);
    },
    async resetMove(item, oldParent, newParent, sort) {
      this.removeFromParent(item);
      item.parent = oldParent;
      const children = await this.getChildren(newParent);
      children.splice(sort, 0, item);
    },
    onMouseDown(e, node) {
      if (this.isDragMove || this.selectEnabled) return;
      clearTimeout(this.timer2);
      this.removeListener();
      this.addListener();
      this.timer2 = setTimeout(() => {
        this.dragNode = node;
        node.$el.classList.add("drag-node");
        let html = $(node.$el).clone();
        html.find("input").each((idx, input) => {
          input.outerHTML = `<div>${input.value}</div>`;
        });

        if (html.find(".tree-item").length > 30) {
          let p = html.find(".tree-item").slice(30);
          p.remove();
          html.find(".tree-hide").removeClass("tree-hide");
        }
        this.dragHtml = html.html();

        let ch = this.dragNode.$el.children[1];
        if (ch) {
          ch.style.pointerEvents = "none";
        }
      }, Platform.isMobile? 200:150);
    },
    onMouseEnter(e, node) {
      if (!this.dragNode) return;
      if (this.dragEndNode) this.dragEndNode.ready = undefined;

      if (this.dragNode.item.id !== node.item.id && !node.item.ready) {
        node.item.ready = true;
      }
      this.dragEndNode = node.item;
    },
    move() {
      this.removeListener();
      if (
        this.dragEndNode &&
        this.dragNode &&
        this.dragNode.item.id !== this.dragEndNode.id
      ) {
        if (this.dragEndNode.ready === "ready-top") {
          //改变顺序
          this.moveItem(this.dragNode.item, this.dragEndNode);
        } else if (this.dragEndNode.ready === "ready-box") {
          //改变层级
          this.moveToInner(this.dragNode.item, this.dragEndNode, true);
        } else if (this.dragEndNode.ready === "ready-bottom") {
          //改变顺序
          this.moveItem(this.dragNode.item, this.dragEndNode, 1);
        }
      }
      if (this.dragEndNode) this.dragEndNode.ready = false;

      if (this.dragNode) {
        let ch = this.dragNode.$el.children[1];
        this.dragNode.$el.classList.remove("drag-node");
        if (ch) {
          ch.style.pointerEvents = "auto";
        }
      }
      this.dragNode = null;
      this.dragHtml = null;
      this.dragEndNode = null;
      clearTimeout(this.timer);
      if (this.isDragMove) {
        this.timer = setTimeout(() => {
          this.isDragMove = false;
        });
      }
    },
    clearDrag() {
      this.removeListener();
      if (this.dragNode) {
        this.dragNode.$el.classList.remove("drag-node");
      }
      this.dragNode = null;
      this.dragHtml = null;
      this.dragEndNode = null;
      this.isDragMove = false;
      clearTimeout(this.timer);
      clearTimeout(this.timer2);
    },
    addListener() {
      // let div = document.createElement('div');
      // div.className = 'tree-shadow';
      // window.document.body.appendChild(div)
      if (Platform.isMobile) {
        window.addEventListener("touchmove", this._mousemove);
        window.addEventListener("touchend", this._mouseup);
      } else {
        window.addEventListener("mousemove", this._mousemove);
        window.addEventListener("mouseup", this._mouseup);
      }
    },
    removeListener() {
      clearTimeout(this.timer3);
      window.document.body.classList.remove("tree-drag");
      if (Platform.isMobile) {
        window.removeEventListener("touchmove", this._mousemove);
        window.removeEventListener("touchend", this._mouseup);
        window.removeEventListener("touchcancel", this._mouseup);
      } else {
        window.removeEventListener("mousemove", this._mousemove);
        window.removeEventListener("mouseup", this._mouseup);
      }

    },
    mouseup(e) {
      if (!Platform.isMobile) {
        this.$refs.listRef.style.overflow = "auto";
        e.preventDefault();
      } else {
        this.isMove = false;
        this.$refs.listRef.style.overflow = "auto";
      }
      clearTimeout(this.timer2);
      this.move();
    },
    mousemove(e) {
      if (!Platform.isMobile) {
        this.$refs.listRef.style.overflow = "hidden";
        e.preventDefault();
      } else {
        this.isMove = true;
        if (!this.dragNode) {
          clearTimeout(this.timer2)
          this.removeListener();
          return;
        }
        this.$refs.listRef.style.overflow = "hidden";
      }
      if (!this.dragNode) return;
      const clientX = e.clientX || (e.touches&&e.touches.length>0? e.touches[0].clientX:0);
      const clientY = e.clientY || (e.touches&&e.touches.length>0? e.touches[0].clientY:0);
      if (this.$refs.drag) {
        this.$refs.drag.style.opacity = '1';
        this.$refs.drag.style.left = clientX + "px";
        this.$refs.drag.style.top = clientY + "px";
      }
      if (!this.isDragMove) {
        clearTimeout(this.timer3);
        this.timer3 = setTimeout(() => {
          window.document.body.classList.add("tree-drag");
        }, 300);
      }
      this.isDragMove = true;

      if (Platform.isMobile) {
        const list = this.$el.querySelectorAll(".tree-item");
        for (let i = 0; i < list.length; ++i) {
          let el = list[i];
          const offset = el.offset();
          if (offset.top < clientY && offset.bottom > clientY) {
            const nodeId = el.getAttribute('data-node-id');
            let node = this.$root.findItemById(nodeId);``
            if (this.dragEndNode && this.dragEndNode.id !== node.id) {
              this.dragEndNode.ready = false;
            }
            if (node.id === this.dragNode.item.id) return;
            this.dragEndNode = node;
            if (offset.top > clientY - 5) {
              this.dragEndNode.ready = "ready-top";
            } else if (offset.bottom < clientY + 5) {
              this.dragEndNode.ready = "ready-bottom";
            } else {
              this.dragEndNode.ready = "ready-box";
            }
            break;
          }
        }
      }
    },

    //复制节点
    async cloneItem(item, parent) {
      await this.cloneApi(item, parent);
    },
  },
};
</script>
