import article from "../../../../api/article";
import { NodeType } from "../../../../components/antv-mindmap/mxs-mindmap.es";

export const NodeMenus = [
  {
    code: "openEditor",
    text: "打开存量",
    hotKey: "",
    canDisabled: (graph, node, options) => {
      return !options.config["openEditor"];
    },
  },
  {
    code: "addChild",
    text: "添加模型",
    hotKey: "Tab",
    canDisabled: (graph, node, options) => {
      return !options.config["addChild"];
    },
  },
  {
    code: "delete",
    text: "删除模型",
    hotKey: "",
    line: true,
    canDisabled: (graph, node, options) => {
      return !options.config["delete"];
    },
  },
  {
    code: "changeIconType",
    text: "标记重点",
    hotKey: "",
    line: false,
    canDisabled: function (graph, node, options) {
      const model = node.getModel();
      if ((!model.iconDetail&&model.iconType === 1) ||
          (model.iconDetail&&model.iconDetail.marked)) {
        this.text = "取消标记";
      } else {
        this.text = "标记重点";
      }
      return !options.config["changeIconType"] || model?.sortId === "0-0";
    },
  },
  {
    code: "cut",
    text: "剪切模型",
    hotKey: "⌘ + X",
    canDisabled: (graph, node, options) => {
      return !options.config["cut"];
    },
  },
  {
    code: "copy",
    text: "复制模型",
    hotKey: "⌘ + C",
    canDisabled: (graph, node, options) => {
      return !options.config["copy"];
    },
  },
  {
    code: "paste",
    text: "粘贴模型",
    hotKey: "⌘ + V",
    line: true,
    canDisabled: (graph, node, options) => {
      return !options.config["paste"];
    },
  },
  {
    code: "expandAll",
    text: "展开所有",
    hotKey: "",
    canDisabled: (graph, node, options) => {
      const model = node.getModel();
      // return  !model.collapsed || ((!model?.children?.length && !model?._children?.length) || !options.config["expandAll"]);
      return (
        (!model?.children?.length && !model?._children?.length) ||
        !options.config["expandAll"]
      );
    },
  },
  {
    code: "collapsed",
    text: "收缩所有",
    hotKey: "",
    canDisabled: (graph, node, options) => {
      const model = node.getModel();
      return (
        (!model?.children?.length && !model._children?.length) ||
        model.collapsed ||
        !options.config["expandAll"]
        // !options.config["collapsed"]
      );
    },
  },
  {
    code: "expand",
    text: "展开模型",
    hotKey: "",
    canDisabled: (graph, node, options) => {
      const model = node.getModel();
      return (
        (!model?.children?.length && !model._children?.length) ||
        !options.config["expand"]
      );
    },
  },
  {
    code: "hideParent",
    text: "折叠上级",
    hotKey: "",
    canDisabled: (graph, node, options) => {
      return true; //!options.config['hideParent'] && graph.canHideParent(node)
    },
  },
  {
    code: "changeRoot",
    text: "从它展开",
    hotKey: "",
    canDisabled: (graph, node, options) => {
      const model = node.getModel();
      return !options.config["changeRoot"] || model?.sortId==='0-0' || model?.id==='root';
    },
  },
  {
    code: "link",
    text: "添加迁移模型",
    hotKey: "",
    canDisabled: (graph, node, options) => {
      return !options.config["link"];
    },
  },
  {
    code: "link2",
    text: "关联模型",
    hotKey: "",
    canDisabled: (graph, node, options) => {
      return !options.config["link2"];
    },
  },
  {
    code: "correlations",
    text: "所有关联模型",
    hotKey: "",
    items: [],
    canDisabled: function (graph, node, options) {
      this.items = options.correlations;
      if (this.items && this.items.length > 0) {
        setTimeout(() => {
          let s = this.items.map((item) => {
            return `<li code="correlation" node-id="${item.id}">${item.title}</li>`;
          });
          document.body.querySelector("#" + this.code).innerHTML = s.join("");
        }, 100);
      }
      return !options.config["correlations"];
    },
  },
  {
    code: "showEdges",
    text: "查看关联",
    hotKey: "",
    canDisabled: (graph, node, options) => {
      return !options.config["showEdges"];
    },
  },
  {
    code: "quadtreeNodes",
    text: "跨树关联节点",
    hotKey: "",
    items: [],
    canDisabled: function (graph, node, options) {
      const model = node.getModel();
      const quadtrees = graph.getQuadtreeNodes(model?.id);
      this.items = quadtrees;
      if (quadtrees.length > 0) {
        article.simpleArticleInfo(quadtrees).then((res) => {
          let s = res.map((el) => {
            return `<li code="subQuadtreeNodes" node-id="${el.id}">${el.title}</li>`;
          });
          document.body.querySelector("#" + this.code).innerHTML = s.join("");
        });
      }

      return !options.config["quadtreeNodes"] || quadtrees.length === 0;
    },
  },
];

export const EdgeMenus = [
  {
    code: "nodeChange",
    text: "双向箭头",
    canDisabled: function (graph, node, options) {
      const model = node.getModel();
      return model?.type === NodeType.linkEdge || graph.hasLink(node);
    },
  },
  {
    code: "doubleArrow",
    text: "箭头类型",
    canDisabled: function (graph, node, options, dom) {
      // const model = node.getModel();
      return true; //model?.type === NodeType.linkEdge || graph.hasLink(node);
    },
  },
  {
    code: "leftArrow",
    text: "左箭头",
    canDisabled: function (graph, node, options, dom) {
      const model = node.getModel();
      return model?.type === NodeType.linkEdge;
    },
  },
  {
    code: "rightArrow",
    text: "右箭头",
    canDisabled: function (graph, node, options, dom) {
      const model = node.getModel();
      return model?.type === NodeType.linkEdge;
    },
  },
  {
    code: "colorType",
    text: "颜色",
    canDisabled: function (graph, node, options, dom) {
      return true;
      // const model = node.getModel();
      // return model?.type === NodeType.linkEdge;
    },
  },
  {
    code: "lineType",
    text: "线条样式",
    canDisabled: function (graph, node, options, dom) {
      return true;
      // const model = node.getModel();
      // return model?.type === NodeType.linkEdge;
    },
  },
  {
    code: "deleteArrow",
    text: "无箭头",
    canDisabled: function (graph, node, options, dom) {
      const model = node.getModel();
      return model?.type === NodeType.linkEdge || !graph.hasLink(node);
    },
  },
  {
    code: "edgeDelete",
    text: "删除关联",
    canDisabled: function (graph, node, options, dom) {
      const model = node.getModel();
      return model?.type !== NodeType.linkEdge;
    },
  },
  {
    code: "labelEdit",
    text: "编辑标签",
    canDisabled: function (graph, node, options, dom) {
      const model = node.getModel();
      return model?.type !== NodeType.linkEdge;
    },
  },
  {
    code: "labelDelete",
    text: "删除标签",
    canDisabled: function (graph, node, options, dom) {
      const model = node.getModel();
      return (
        (model?.type === NodeType.linkEdge &&
          (model?.labelType === 2 || !model?.title)) ||
        model?.type !== NodeType.linkEdge
      );
    },
  },
];
export const CanvasMenus = [
  {
    code: "zoomOut",
    text: "放大",
  },
  {
    code: "zoomIn",
    text: "缩小",
  },
  {
    code: "fitCenter",
    text: "缩放到合适位置",
  },
  {
    code: "downloadPage",
    text: "导出图片",
  },
];

export const getMenusDom = (menus, graph, node, options) => {
  const dom = document.createElement("div");
  dom.className = "mind-menu shadow bounceIn thin-border bgblur right-menu";
  const html = [];
  menus.forEach((menu) => {
    if (!menu.canDisabled || !menu.canDisabled(graph, node, options)) {
      let s = "";
      if (menu.items) {
        s = `<div class="float-ul${
          menu.items.length > 1 ? "" : " float-more"
        }"><ul id="${
          menu.code
        }" class="shadow bounceIn thin-border bgblur right-menu"><div class="list-loading d-center d-column"><div class="ui-loading"></div><span>加载中...</span></div></ul></div></li></div>`;
      }
      html.push(
        `<li code="${menu.code}"${menu.items ? ' class="sub-menus"' : ""}
        ${menu.text === "标记重点" ? ' style="color:#DF7271;"' : ""}>
        ${menu.text}<span>${menu.hotKey || ""}</span>${s}</li>`
      );
      if (menu.line) {
        html.push('<div class="el-dropdown-menu__item--divided"></div>');
      }
    }
  });
  dom.innerHTML = `<ul>${html.join("")}</ul>`;
  return dom;
};
