<template>
  <div
      class="tree-group"
      v-if="!selectEnabled || (filterItem && filterItem.id !== item.id)"
  >
    <MobileDiv
        class="tree-item"
        ref="item"
        v-if="item"
        :data-node-id="item.id"
        :data-sort="sort"
        :class="[
            'tree-level-'+level,
        ready || '',
        selectEnabled
          ? activeItem && activeItem.id === item.id
            ? 'active'
            : ''
          : item.active
          ? 'active'
          : '',
      ]"
        @contextmenu="onContextMenu($event, this)"
        :style="{ paddingLeft: level * indent + 'px' }"
        @down="onMouseDown"
        @enter="onMouseEnter"
        @leave="onMouseLeave"
        @move="onMouseMove"
        @click="onClick"
    >
      <div class="tree-inner">
        <i
            class="tree-dot icon-new-03 d-center tree-arrow"
            :class="{
            expanded: selectEnabled ? expandMap[item.id] : item.expand,
          }"
            @click="onExpand($event, item)"
            v-if="item.hasChildren"
        ></i>
        <i
            class="tree-dot el-icon el-tree-node__expand-icon dot-icon d-center"
            v-else
        ></i>
        <slot :data="item" :node="this"/>
      </div>
    </MobileDiv>
    <collapse-expand
        v-if="item.hasChildren"
        :expand="selectEnabled ? expandMap[item.id] : item.expand"
        :class="{
        ext:
          (selectEnabled ? expandMap[item.id] : item.expand) && item.children,
      }"
    >
      <div
          v-if="
          (selectEnabled ? expandMap[item.id] : item.expand) &&
          item.loading &&
          showLoading
        "
          class="loading-view d-center"
      >
        <div class="loading-text d-center"/>
      </div>
      <tree-node
          v-for="(child, index) in item.children"
          :sort="sort + '-' + index"
          :indent="indent"
          :activeItem="activeItem"
          :selectEnabled="selectEnabled"
          :expandMap="expandMap"
          :filter-item="filterItem"
          :key="child.id"
          :item="child"
          :level="level + 1"
          :showLoading="showLoading"
          v-model:ready="child.ready"
          @onSelect="onClick"
          @onDragDown="onMouseDown"
          @onDragEnter="onMouseEnter"
          @onMouseMove="onMouseMove"
          @onContextMenu="onContextMenu"
          @update:ready="onChange"
          @onExpand="onExpand"
      >
        <template #default="{ data, node }">
          <slot :data="data" :node="node"></slot>
        </template>
      </tree-node>
    </collapse-expand>
  </div>
</template>
<script>
import CollapseExpand from "./CollapseExpand";
import MobileDiv from "./MobileDiv";

export default {
  components: {
    MobileDiv,
    CollapseExpand,
  },
  props: {
    sort: {type: String, default: ""},
    item: {type: Object, default: () => null},
    ready: {type: [Boolean, String], default: false},
    level: {type: Number, default: 0},
    indent: {type: [Number, String], default: 20},
    showLoading: {type: Boolean, default: false},
    selectEnabled: {type: Boolean, default: false},
    activeItem: {type: Object, default: undefined},
    expandMap: {type: Object, default: () => ({})},
    filterItem: {type: Object, default: undefined},
  },
  data() {
    return {};
  },
  watch: {
    "item.expand": {
      handler(val, old) {
        if (val !== old) {
          this.$api.article.expand(this.item.id, val);
        }
      },
    },
  },
  methods: {
    test(p, d) {
      // console.log('-sss--', p, d)
    },
    focus() {
      if (this.$refs.item) {
        this.$refs.item.addClass("tree-edit");
      }
      let input = this.$el.querySelector("input");
      if (input) {
        input.focus();
      }
    },
    onContextMenu(e, node) {
      e.preventDefault();
      this.$emit("onContextMenu", e, node);
      return false;
    },
    onExpand(e, item) {
      this.$emit("onExpand", e, item);
    },
    onClick(e, item) {
      if (e.target.classList.contains("tree-item")) {
        this.$emit("onSelect", e, item || this.item);
      }
    },
    onMouseDown(e, node) {
      if (e.target.parent) {
        let el = e.target.parent("tree-item", true);
        if (el && el.hasClass && el.hasClass("tree-edit")) return;
      }
      this.$emit("onDragDown", e, node || this);
    },
    onMouseLeave(e) {
      if (this.$refs.item) {
        this.$refs.item.removeClass("tree-hover");
      }
    },
    onMouseEnter(e, node) {
      if (this.$refs.item) {
        this.$refs.item.addClass("tree-hover");
      }
      this.$emit("onDragEnter", e, node || this);
    },
    onChange() {
    },
    getMoveType(e) {
      if (!e.target.offset) return;
      let offset = e.target.offset();
      if (e.clientY < offset.top + 5) {
        return "ready-top";
      } else if (offset.bottom - 5 < e.clientY) {
        return "ready-bottom";
      } else {
        return "ready-box";
      }
    },
    onMouseMove(e, node) {
      // if (!item.ready || !e.target.offset) return;
      // this.$emit('update:ready', this.getMoveType(e));
      this.$emit("onMouseMove", e, node || this);
    },
  },
};
</script>
